import Button from '@core-ui/button';
import { DATE_FORMAT } from 'app/consts/app';
import { formatTime } from 'app/utils/dates';
import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { useIntl } from 'components/Intl';
import { useAppSelector } from 'hooks/useAppSelector';
import selector from 'pages/backoffice/Modals/ImageCarouselModal/selector';
import { X } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'components/Intl';
import useStyles from './styles';

interface IProps {
  onClose: () => void;
}

const SourceFileComments = (props: IProps) => {
  const { onClose } = props;

  const { createdAt, status, uploadedBy, id } = useAppSelector(selector);

  const styles = useStyles();
  const intl = useIntl();

  let createdAtFormatted: string | JSX.Element = <FormattedMessage id="label.no_info" />;

  if (createdAt) {
    if (typeof createdAt === 'string') {
      createdAtFormatted = createdAt.replace(/\./g, '/');
    } else {
      createdAtFormatted = formatTime(createdAt, { dateFormat: DATE_FORMAT });
    }
  }

  const uploadedByName = uploadedBy || <FormattedMessage id="label.no_info" />;
  const documentStatus = status ? status.replace(/_/g, ' ') : <FormattedMessage id="label.no_info" />;

  return (
    <Grid container flexDirection="column" alignItems="center" justifyContent="flex-start" sx={styles.comments}>
      <Grid container padding={4} sx={styles.commentsHeader} alignItems="center" justifyContent="space-between">
        <Typography variant="body1" fontWeight="semiBold" color={COLOURS.WHITE}>
          <FormattedMessage id="imageCarousel.document_id" values={{ id }} />
        </Typography>

        <Button
          variant="text"
          onClick={onClose}
          aria-label={intl.formatMessage({ id: 'aria.label.close_modal' })}
          icon={
            <SvgIcon htmlColor={COLOURS.WHITE}>
              <X />
            </SvgIcon>
          }
        />
      </Grid>

      <Grid container item wrap="nowrap" direction="column" flex="1" sx={styles.iframe}>
        <Grid item>
          <Typography variant="body1" component="span" color={COLOURS.Coconut.Solid[300]}>
            <FormattedMessage id="imageCarousel.uploaded_date" />

            <Typography variant="body1" component="span" marginLeft={1} color={COLOURS.WHITE}>
              {createdAtFormatted}
            </Typography>
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="body1" component="span" color={COLOURS.Coconut.Solid[300]}>
            <FormattedMessage id="imageCarousel.uploaded_by" />

            <Typography variant="body1" component="span" marginLeft={1} color={COLOURS.WHITE}>
              {uploadedByName}
            </Typography>
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="body1" component="span" color={COLOURS.Coconut.Solid[300]}>
            <FormattedMessage id="imageCarousel.status" />

            <Typography variant="body1" component="span" marginLeft={1} color={COLOURS.WHITE}>
              {documentStatus}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SourceFileComments;
