export enum LEDGER_DOCUMENT_TAB_ENUM {
  INFORMATION = 'information',
  LINES = 'lines',
}

// TODO: в сгенеренном энаме DocTypeLite есть лишний для фильтров леджера элемент
// "SingleRequest" и его невозможно убрать на бэке, поэтому используем кастомный
export enum LEDGER_DOCUMENT_TYPE_ENUM {
  INVOICE = 'invoice',
  RECEIPT = 'receipt',
}

export enum LEDGER_DOCUMENT_PREVIOUS_SECTION_ENUM {
  WALLETS = 'wallets',
  CONTRACTS = 'contracts',
  LEDGER = 'ledger',
  REQUESTS = 'requests',
}

export const LEDGER_TABLE_FILTERS_HEIGHT = 84;

export const DRAWER_WIDTH = 372;
export const DRAWER_GRID_ITEM_WIDTH = 340;
export const SINGLE_DOCUMENT_PREVIEW_PANEL_DEFAULT_WIDTH = 750;
export const SINGLE_DOCUMENT_PREVIEW_PANEL_MIN_WIDTH = 340;
export const SINGLE_DOCUMENT_PREVIEW_PANEL_MAX_WIDTH = 960;
export const SINGLE_DOCUMENT_INFO_TAB_LABEL_WIDTH = 220;
export const SINGLE_DOCUMENT_INFO_TAB_VALUE_FIELD_WIDTH = 450;
export const SINGLE_DOCUMENT_INFO_TAB_SELECTOR_CONTAINER_WIDTH = '75%';
export const SINGLE_DOCUMENT_INFO_TAB_SELECTORS_WIDTH = 448;
export const SINGLE_DOCUMENT_INFO_TAB_DIVIDER_WIDTH = 678;
export const SINGLE_DOCUMENT_LINE_COLUMN_WIDTH = 300;
export const LEDGER_TOP_PADDING = 24;
