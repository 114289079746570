import { HeaderCell, TableRow } from '@core-ui/table';
import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
import React from 'react';
import { HEADERS } from './consts';

const TableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        {HEADERS.map((column) => (
          <HeaderCell
            key={column.key}
            align={column.align}
            right={column.right}
            shadow={column.shadow}
            sticky
            style={{
              minWidth: column.width,
              width: column.width,
              maxWidth: column.width,
            }}
          >
            <Box>{column.title}</Box>
          </HeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
