import { PAYMENT_REQUEST_TYPE_ENUM } from '@/pages/backoffice/Requests/types';
import { COLOURS } from '@core-ui/styles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'components/Intl';
import React, { ReactNode } from 'react';

interface IProps {
  selected: PAYMENT_REQUEST_TYPE_ENUM;
  type: ReactNode;
}

const Title = (props: IProps) => (
  <Grid container wrap="nowrap" columnGap={1}>
    <Typography component="span" variant="h3">
      <FormattedMessage
        id="modal.backoffice.create_edit_request.title"
        values={{
          type: props.type,
          variant: (
            <Typography component="span" color={COLOURS.Fig.Solid[600]} variant="h3" textTransform="lowercase">
              <FormattedMessage id={props.selected} />
            </Typography>
          ),
        }}
      />
    </Typography>
  </Grid>
);

export default Title;
