import Button from '@core-ui/button';
import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { POST } from '@/api/oceanApi';
import { setResponseError } from '@/app/actions';
import OLMap from '@/components/OLMap';
import { useAppSelector } from '@/hooks/useAppSelector';
import { getPinMapParams } from '@/pages/backoffice/Modals/CreateEditRequestModal/selector';
import { IOpenLayerFeature } from '@/pages/backoffice/Requests/types';
import { CaretDown, CaretUp, X } from '@phosphor-icons/react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

interface IProps {
  createRemoveFeature: (coordinates: number[], deleted?: boolean) => void;
  clearAllFeatures: () => void;
  loading: boolean;
  haveSelectedPoints: boolean;
  features: IOpenLayerFeature[];
  setFeatures: (features: IOpenLayerFeature[]) => void;
}

const PinMap = (props: IProps) => {
  const { createRemoveFeature, loading, haveSelectedPoints, clearAllFeatures, features, setFeatures } = props;

  const { boatId, requestId } = useAppSelector(getPinMapParams);
  const [show, setShow] = useState(true);
  const callChildClearPoints = useRef<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const getFeatures = async () => {
      if (!boatId || !requestId) {
        return;
      }

      try {
        const res = await POST('requests/get_map_points', {
          request_ids: [Number(requestId)],
          boat_id: Number(boatId),
        });
        setFeatures(res as IOpenLayerFeature[]);
      } catch (e) {
        dispatch(setResponseError(e));
      }
    };

    getFeatures();
  }, []);

  const clearPoints = () => {
    callChildClearPoints.current();
    clearAllFeatures();
  };

  const toggleMap = () => setShow((state) => !state);

  return (
    <>
      <Grid item container wrap="nowrap" columnGap={1} alignItems="center" justifyContent="space-between">
        <Grid item zeroMinWidth xs>
          <Typography component="span" fontWeight="semiBold" noWrap>
            Place a pin on the boat map{' '}
          </Typography>

          <Typography component="span" color={COLOURS.Coconut.Solid[500]} noWrap>
            (optional)
          </Typography>
        </Grid>
        {(haveSelectedPoints || features.length > 0) && (
          <Button
            compact
            onClick={clearPoints}
            startIcon={
              <SvgIcon htmlColor={COLOURS.Strawberry.Solid[600]}>
                <X />
              </SvgIcon>
            }
            variant="text"
            label={<Typography color={COLOURS.Strawberry.Solid[600]}>Clear all points</Typography>}
          />
        )}
        <IconButton onClick={toggleMap}>
          <SvgIcon>{show ? <CaretUp /> : <CaretDown />}</SvgIcon>
        </IconButton>
      </Grid>
      <Slide direction="up" in={show} appear={false}>
        <Grid item xs sx={{ ...(loading && { pointerEvents: 'none' }) }}>
          <OLMap
            features={features}
            edit
            createRemoveFeature={createRemoveFeature}
            callChildClearPoints={callChildClearPoints}
          />
        </Grid>
      </Slide>
    </>
  );
};

export default PinMap;
