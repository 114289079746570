import { PUT } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { showNotification } from '@/components/Notification/actions';
import { ApproveStatusEnum, RequestDetailOutputV2Schema, UpdateApprove } from '@/generated';
import { getLedgerDocument } from '@/pages/backoffice/Ledger/LedgerDocumentRoot/actions';
import { getLedgerList } from '@/pages/backoffice/Ledger/LedgerList/actions';
import { closeBackofficeModal, toggleModalLoading } from '@/pages/backoffice/Modals/actions';
import { PARENT_PAGE } from 'pages/backoffice/Modals/consts';
import { updateItemInRequestList } from 'pages/backoffice/Requests/RequestsList/actions';
import { getSingleRequest, setSingleRequest } from 'pages/backoffice/Requests/ViewRequestPanel/actions';
import { getViewPanelStateValue } from 'pages/backoffice/Requests/ViewRequestPanel/selector';
import { Action } from 'redux-actions';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* declineDocument({ payload }: Action<actions.IDeclineDocument>) {
  try {
    const { id, parentPage, requestId, declineReason } = payload;

    yield put(toggleModalLoading());

    yield call(PUT, '/approves', [
      {
        document_id: id,
        status: ApproveStatusEnum.DECLINED,
        reason: declineReason,
      },
    ] as UpdateApprove[]);

    switch (parentPage) {
      case PARENT_PAGE.LEDGER_LIST:
        yield put(getLedgerList(null));
        break;

      case PARENT_PAGE.LEDGER_SINGLE_DOCUMENT:
        yield put(getLedgerDocument(null));
        break;

      case PARENT_PAGE.REQUESTS:
        yield put(getSingleRequest({ id: requestId }));
        yield take(setSingleRequest);
        const updatedRequest: RequestDetailOutputV2Schema = yield select(getViewPanelStateValue);
        yield put(updateItemInRequestList({ item: updatedRequest }));
        break;
    }

    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success.text.document_declined',
      })
    );
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(closeBackofficeModal());
  }
}

export default [takeLatest(actions.declineDocument, declineDocument)];
