import { getList, resetState, setList } from '@core-ui/reducers_handlers';
import { Nullable } from '@core-ui/types';
import { BudgetRemainingTotal, CARemainingDocLine } from '@/generated';
import { PARENT_PAGE } from 'pages/backoffice/Modals/consts';
import { createAction } from 'redux-actions';

export const reducerName = 'backoffice.modals.approve_document';

export interface IApproveDocumentList {
  id: number;
}

export const getApproveDocumentList = getList<IApproveDocumentList>(reducerName);
export const setApproveDocumentList = setList<CARemainingDocLine>(reducerName);

export const setDocumentTotalPrice = createAction<number>(`${reducerName}.SET_DOCUMENT_TOTAL_PRICE`);
export const setTotalRemaining = createAction<Nullable<BudgetRemainingTotal>>(`${reducerName}.SET_TOTAL_REMAINING`);

export const resetApproveDocumentList = resetState(reducerName);

export interface IApproveDocument {
  id: number;
  parentPage: PARENT_PAGE;
  requestId?: number;
}

export const approveDocument = createAction<IApproveDocument>(`${reducerName}.APPROVE_DOCUMENT`);
