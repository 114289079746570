import { COLOURS } from '@core-ui/styles';
import { TableCell, TableRow } from '@core-ui/table';
import Typography from '@mui/material/Typography';
import { getCostArticleMappingEnabled } from '@/app/selectors';
import { AmountFormatter } from '@/app/types/common';
import { CARemainingDocLine } from '@/generated';
import { useAppSelector } from '@/hooks/useAppSelector';
import React from 'react';

interface IProps {
  item: CARemainingDocLine;
  amountFormatter: AmountFormatter;
}

const ApproveDocumentModalListRow = (props: IProps) => {
  const { item, amountFormatter } = props;

  const costArticleMappingEnabled = useAppSelector(getCostArticleMappingEnabled);

  const isBudgetOverrun = item.doc_ca_price > item.remaining;
  const costArticleName = costArticleMappingEnabled ? item.cost_article_clients_name : item.cost_article_name;

  return (
    <TableRow>
      <TableCell>{costArticleName}</TableCell>

      <TableCell align="right">
        <Typography
          fontFamily="mono"
          fontWeight="semiBold"
          color={isBudgetOverrun ? COLOURS.Strawberry.Solid[600] : COLOURS.Coconut.Solid[900]}
        >
          {amountFormatter(item.doc_ca_price)}
        </Typography>
      </TableCell>

      <TableCell align="right">
        <Typography fontFamily="mono" fontWeight="semiBold">
          {amountFormatter(item.remaining)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default ApproveDocumentModalListRow;
