import { BACKEND_URL } from '@/app/consts/env';
import { ROUTES } from '@/app/consts/routes';
import { exhaustiveGuard } from '@/app/utils/app';
import ApprovalFlowStatus from '@/components/ApprovalFlowStatus';
import NoDataContainer from '@/components/NoDataContainer';
import history from '@/history';
import { useAppSelector } from '@/hooks/useAppSelector';
import { BACKOFFICE_TABS } from '@/pages/backoffice/consts';
import { SINGLE_CONTRACT_TAB_ENUM } from '@/pages/backoffice/Contracts/consts';
import { SingleContractQueryParams } from '@/pages/backoffice/Contracts/types';
import { LEDGER_DOCUMENT_PREVIOUS_SECTION_ENUM } from '@/pages/backoffice/Ledger/consts';
import LedgerDocumentHeaderMoreButton from '@/pages/backoffice/Ledger/LedgerDocumentHeader/LedgerDocumentHeaderMoreButton';
import LedgerDocumentTabs from '@/pages/backoffice/Ledger/LedgerDocumentHeader/LedgerDocumentTabs';
import ValidationButtons from '@/pages/backoffice/Ledger/LedgerDocumentHeader/ValidationButtons';
import { getNoDataContainerData } from '@/pages/backoffice/Ledger/LedgerDocumentRoot/selector';
import { LedgerDocumentHeaderSkeleton } from '@/pages/backoffice/Ledger/skeletons';
import { LedgerDocumentQueryParams } from '@/pages/backoffice/Ledger/types';
import Button from '@core-ui/button';
import { useQuery } from '@core-ui/redux-router';
import { BREAKPOINTS } from '@core-ui/styles';
import { buildURL } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ArrowLeft, DownloadSimple } from '@phosphor-icons/react';
import { FormattedMessage, IntlMessageKeys } from 'components/Intl';
import { IRequestsQueryParams } from 'pages/backoffice/Requests/types';
import React from 'react';
import selector from './selector';

const LedgerDocumentHeader = () => {
  const { item, boatId, currencyFormatter, budgetApproveFlowActive } = useAppSelector(selector);

  // TODO: модалка мастер-документа делает эту логику кнопки назад неактуальной т.к. теперь переход на страницу
  //  будет всегда осуществляться только со страницы Леджера. Поэтому мб всю эту логику нужно удалить
  //  но не факт, что она нам не потребуется в будущем т.к. модалка мастер-документа это +- тестовая фича
  //  которая хз зайдёт клиентам или нет
  const { previous_section = LEDGER_DOCUMENT_PREVIOUS_SECTION_ENUM.LEDGER, previous_id } =
    useQuery<LedgerDocumentQueryParams>();

  let prevButtonLabel: IntlMessageKeys;
  let prevPagePath: string;

  switch (previous_section) {
    case LEDGER_DOCUMENT_PREVIOUS_SECTION_ENUM.LEDGER:
      prevButtonLabel = 'label.back_to_ledger';
      prevPagePath = `/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.LEDGER}`;
      break;
    case LEDGER_DOCUMENT_PREVIOUS_SECTION_ENUM.WALLETS:
      prevButtonLabel = 'label.back_to_wallet';
      prevPagePath = buildURL(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.WALLETS}/${previous_id}`);
      break;
    case LEDGER_DOCUMENT_PREVIOUS_SECTION_ENUM.CONTRACTS:
      prevButtonLabel = 'label.back_to_contract';
      prevPagePath = buildURL<SingleContractQueryParams>(
        `/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.CONTRACTS}/${previous_id}`,
        {
          tab: SINGLE_CONTRACT_TAB_ENUM.TRANSACTIONS,
        }
      );
      break;
    case LEDGER_DOCUMENT_PREVIOUS_SECTION_ENUM.REQUESTS:
      prevButtonLabel = 'label.back_to_request';
      prevPagePath = buildURL<IRequestsQueryParams>(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.REQUESTS}`, {
        panelMode: 'view',
        selectedRequestId: Number(previous_id),
      });
      break;
    default:
      prevButtonLabel = 'label.back_to_ledger';
      prevPagePath = `/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.LEDGER}`;
      exhaustiveGuard(previous_section);
  }

  const handleReturnToPreviousPage = () => {
    history.push(buildURL(prevPagePath));
  };

  const handleDownloadCsv = () => {
    if (item) {
      window.open(buildURL(`${BACKEND_URL}/document/${item.id}/csv`));
    }
  };

  const title = item?.vendor ?? <FormattedMessage id="label.unknown_name" />;

  return (
    <NoDataContainer selector={getNoDataContainerData} loader={<LedgerDocumentHeaderSkeleton />} direction="column">
      <Grid item container direction="column" padding="24px 0">
        <Grid item container width="100%" marginBottom={2} justifyContent="space-between">
          <Button
            variant="text"
            color="blue"
            compact
            label={<FormattedMessage id={prevButtonLabel} />}
            startIcon={<ArrowLeft />}
            onClick={handleReturnToPreviousPage}
          />
        </Grid>

        <Grid item container marginBottom={3} marginRight={4} gap={4} justifyContent="space-between">
          <Grid item container gap={4} width="auto">
            <Typography variant="h3">
              {title}
            </Typography>

            <ApprovalFlowStatus item={item} />
          </Grid>

          <Grid item container gap={2} width="auto">
            {budgetApproveFlowActive && <ValidationButtons item={item} />}

            <LedgerDocumentHeaderMoreButton item={item} />
          </Grid>
        </Grid>

        <Grid item marginBottom={7}>
          <Typography fontWeight="semiBold">{currencyFormatter(item?.currency_id, item?.total)}</Typography>
        </Grid>

        <Grid item container width="100%" justifyContent="space-between" wrap="nowrap" maxWidth={BREAKPOINTS.XL}>
          <Grid width="auto">
            <LedgerDocumentTabs />
          </Grid>

          <Button
            variant="text"
            color="blue"
            compact
            label={<FormattedMessage id="label.download_csv" />}
            startIcon={<DownloadSimple />}
            onClick={handleDownloadCsv}
          />
        </Grid>
      </Grid>
    </NoDataContainer>
  );
};

export default LedgerDocumentHeader;
