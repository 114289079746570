import Autocomplete from '@core-ui/autocomplete';
import Button from '@core-ui/button';
import Modal from '@core-ui/modal';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { useAppSelector } from '@/hooks/useAppSelector';
import { ISendForApprovalItemModal } from '@/pages/backoffice/Modals/types';
import { ArrowRight } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import { sendDocumentForApproval } from './actions';
import selector from './selector';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const SendForApprovalModal = (props: IProps) => {
  const { opened, onClose } = props;

  const { clientCostArticlesOptions, loading, invoiceCode, documentId } = useAppSelector(selector);
  const [selectedErpCode, setSelectedErpCode] = useState<string | undefined>(invoiceCode);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedErpCode(invoiceCode);
  }, [invoiceCode]);

  const handleSendForApproval = () => {
    dispatch(
      sendDocumentForApproval({
        documentId,
        invoiceCode: selectedErpCode,
      } as ISendForApprovalItemModal)
    );
  };

  return (
    <Modal
      open={opened}
      maxWidth="md"
      onClose={onClose}
      title={<FormattedMessage id="modal.backoffice.send_document_for_approval.title" />}
    >
      <DialogContent>
        <Grid container columnGap={3}>
          <Grid item xs>
            <Autocomplete
              options={clientCostArticlesOptions}
              value={selectedErpCode}
              onChange={setSelectedErpCode}
              label={<FormattedMessage id="label.pick_category" />}
              dataTestId="send-for-approval-modal-autocomplete"
            />
          </Grid>

          <Grid item component={DialogActions}>
            <Button
              variant="contained"
              color="blue"
              size="large"
              loading={loading}
              autoFocus={Boolean(selectedErpCode)}
              disabled={!selectedErpCode || loading}
              onClick={handleSendForApproval}
              label={<FormattedMessage id="label.confirm" />}
              endIcon={<ArrowRight />}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Modal>
  );
};

export default SendForApprovalModal;
