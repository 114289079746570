import { COLOURS } from '@core-ui/styles';
import Skeleton from '@mui/material/Skeleton';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';

const styles: SxProps<Theme> = {
  background: COLOURS.Coconut.Solid[100],
  transform: 'none',
};

const ApproveDocumentTableModalSkeleton = () => {
  return (
    <>
      <Skeleton width={200} height={70} sx={styles} />

      <Skeleton width={200} height={70} sx={styles} />
    </>
  );
};

export default ApproveDocumentTableModalSkeleton;
