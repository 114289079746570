import { IDeclineDocumentModalData } from '@/pages/backoffice/Modals/DeclineDocumentModal/actions';
import { PARENT_PAGE } from 'pages/backoffice/Modals/consts';

export default class DeclineDocumentModalData implements IDeclineDocumentModalData {
  id: number;
  parentPage: PARENT_PAGE;
  requestId?: number;

  constructor(id: number, parentPage: PARENT_PAGE, requestId?: number) {
    this.id = id;
    this.parentPage = parentPage;
    this.requestId = requestId;
  }
}
