import { PARENT_PAGE } from 'pages/backoffice/Modals/consts';
import { createAction } from 'redux-actions';

export const reducerName = 'backoffice.modals.decline_document';

export interface IDeclineDocumentModalData {
  id: number;
  parentPage: PARENT_PAGE;
  requestId?: number;
}

export interface IDeclineDocument extends IDeclineDocumentModalData {
  declineReason?: string;
}

export const declineDocument = createAction<IDeclineDocument>(`${reducerName}.DECLINE_DOCUMENT`);
