import { GET, PUT } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { getCostArticleMappingEnabled, vehicleIdSelector } from '@/app/selectors';
import { showNotification } from '@/components/Notification/actions';
import { ApproveStatusEnum, RemainingsWithDoc, RequestDetailOutputV2Schema, UpdateApprove } from '@/generated';
import { getLedgerDocument } from '@/pages/backoffice/Ledger/LedgerDocumentRoot/actions';
import { getLedgerList } from '@/pages/backoffice/Ledger/LedgerList/actions';
import { closeBackofficeModal, toggleModalLoading } from '@/pages/backoffice/Modals/actions';
import { buildURL } from '@core-ui/url';
import { PARENT_PAGE } from 'pages/backoffice/Modals/consts';
import { updateItemInRequestList } from 'pages/backoffice/Requests/RequestsList/actions';
import { getSingleRequest, setSingleRequest } from 'pages/backoffice/Requests/ViewRequestPanel/actions';
import { getViewPanelStateValue } from 'pages/backoffice/Requests/ViewRequestPanel/selector';
import { Action } from 'redux-actions';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* approveDocument({ payload }: Action<actions.IApproveDocument>) {
  try {
    const { id, parentPage, requestId } = payload;

    yield put(toggleModalLoading());

    yield call(PUT, '/approves', [
      {
        document_id: id,
        request_id: requestId,
        status: ApproveStatusEnum.APPROVED,
      },
    ] as UpdateApprove[]);

    switch (parentPage) {
      case PARENT_PAGE.LEDGER_LIST:
        yield put(getLedgerList(null));
        break;

      case PARENT_PAGE.LEDGER_SINGLE_DOCUMENT:
        yield put(getLedgerDocument(null));
        break;

      case PARENT_PAGE.REQUESTS:
        yield put(getSingleRequest({ id: requestId }));
        yield take(setSingleRequest);
        const updatedRequest: RequestDetailOutputV2Schema = yield select(getViewPanelStateValue);
        yield put(updateItemInRequestList({ item: updatedRequest }));
        break;
    }

    yield all([
      put(closeBackofficeModal()),
      put(
        showNotification({
          variant: 'success',
          titleId: 'notification.success.text.document_approved',
        })
      ),
    ]);
  } catch (e) {
    yield all([call(responseError, e), put(toggleModalLoading())]);
  }
}

function* getApproveDocumentList({ payload }: Action<actions.IApproveDocument>) {
  try {
    yield put(toggleModalLoading());

    const { id } = payload;
    const boatId: number = yield select(vehicleIdSelector);
    const userCostArticlesMappingEnabled: boolean = yield select(getCostArticleMappingEnabled);

    const url = buildURL(`/budgets/${boatId}/remainings/documents/${id}`, {
      is_mapping: userCostArticlesMappingEnabled,
    });

    const documentData: RemainingsWithDoc = yield call(GET, url);

    yield all([
      put(
        actions.setApproveDocumentList({
          value: documentData.cost_articles || [],
          hasData: true,
          isAllDataReceived: true,
        })
      ),
      put(actions.setDocumentTotalPrice(documentData.document_total_price)),
      put(actions.setTotalRemaining(documentData.total_remaining ?? null)),
    ]);
  } catch (e) {
    yield call(responseError, e);
    yield put(
      actions.setApproveDocumentList({
        error: e as Error,
        hasData: false,
        isAllDataReceived: false,
      })
    );
  } finally {
    yield put(toggleModalLoading());
  }
}

export default [
  takeLatest(actions.approveDocument, approveDocument),
  takeLatest(actions.getApproveDocumentList, getApproveDocumentList),
];
