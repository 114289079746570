import { IApproveDocument } from '@/pages/backoffice/Modals/ApproveDocumentModal/actions';
import { PARENT_PAGE } from 'pages/backoffice/Modals/consts';

export default class ApproveDocumentModalData implements IApproveDocument {
  id: number;
  parentPage: PARENT_PAGE;
  requestId?: number;

  constructor(id: number, parentPage: PARENT_PAGE, requestId?: number) {
    this.id = id;
    this.parentPage = parentPage;
    this.requestId = requestId;
  }
}
