import Button from '@core-ui/button';
import Modal from '@core-ui/modal';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { useAppSelector } from '@/hooks/useAppSelector';
import { deleteSingleContractDocument } from '@/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsList/SingleContractRow/actions';
import { Trash } from '@phosphor-icons/react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import selector from './selector';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const DeleteSingleContractTransactionModal = (props: IProps) => {
  const { opened, onClose } = props;

  const dispatch = useDispatch();
  const { id, status, loading } = useAppSelector(selector);

  const handleConfirm = () => {
    dispatch(deleteSingleContractDocument({ id, status }));
  };

  return (
    <Modal
      open={opened}
      maxWidth="xs"
      onClose={onClose}
      title={<FormattedMessage id="modal.backoffice.delete_single_contract_transaction.title" />}
      subtitle={<FormattedMessage id="modal.backoffice.delete_single_contract_transaction.subtitle" />}
    >
      <DialogContent>
        <Grid container justifyContent="flex-end" gap={3} component={DialogActions} disableSpacing>
          <Button
            color="blue"
            size="large"
            variant="outlined"
            onClick={onClose}
            label={<FormattedMessage id="label.cancel" />}
          />

          <Button
            color="blue"
            size="large"
            autoFocus
            variant="contained"
            onClick={handleConfirm}
            loading={loading}
            disabled={loading}
            label={<FormattedMessage id="label.delete" />}
            startIcon={<Trash />}
          />
        </Grid>
      </DialogContent>
    </Modal>
  );
};

export default DeleteSingleContractTransactionModal;
