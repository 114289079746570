import { parseDate } from '@core-ui/dates';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DATE_FORMAT } from '@/app/consts/app';
import { AutocompleteForm, DatePickerForm, TextFieldForm, TextFieldMaskedForm } from '@/components/Form';
import { useAppSelector } from '@/hooks/useAppSelector';
import AttachFiles from '@/pages/backoffice/Modals/CreateEditRequestModal/components/AttachFiles';
import { IOpenLayerFeature, PAYMENT_REQUEST_TYPE_ENUM } from '@/pages/backoffice/Requests/types';
import { isEqual } from 'lodash-es';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import { submitRequestForm } from '../../actions';
import { CREATE_REQUEST_FORM_ID } from '../../consts';
import { IRequestForm } from '../../types';
import PinMap from '../PinMap';
import selector, { invoiceTypeOptions } from './selector';
import validate from './validate';

interface IProps {
  reqType: PAYMENT_REQUEST_TYPE_ENUM;
  formInitial: Nullable<IRequestForm>;
}

const BaseVariant = (props: IProps) => {
  const { reqType, formInitial } = props;

  const dispatch = useDispatch();
  const { departmentsOptions, currenciesOptions, ccOptions, loading } = useAppSelector(selector);

  const [defaultFiles, setDefaultFiles] = useState<File[]>([]);
  const [quoteFiles, setQuoteFiles] = useState<File[]>([]);
  const [invoiceFiles, setInvoiceFiles] = useState<File[]>([]);
  const [receiptFiles, setReceiptFiles] = useState<File[]>([]);
  const [mapPoints, setMapPoints] = useState<number[][]>(formInitial?.map_points ?? []);
  const [features, setFeatures] = useState<IOpenLayerFeature[]>([]);

  const removeDefaultFile = (fileToDelete: File) => {
    setDefaultFiles((state) => state.filter((f) => f.name !== fileToDelete.name));
  };

  const removeQuoteFile = (fileToDelete: File) => {
    setQuoteFiles((state) => state.filter((f) => f.name !== fileToDelete.name));
  };

  const removeInvoiceFile = (fileToDelete: File) => {
    setInvoiceFiles((state) => state.filter((f) => f.name !== fileToDelete.name));
  };

  const removeReceiptFile = (fileToDelete: File) => {
    setReceiptFiles((state) => state.filter((f) => f.name !== fileToDelete.name));
  };

  const createRemoveFeature = (coordinates: number[], deleted?: boolean) => {
    deleted
      ? setMapPoints((pointsCoord) => pointsCoord.filter((coord) => !isEqual(coord, coordinates)))
      : setMapPoints((points) => [...points, coordinates]);
  };

  const clearAllFeatures = () => setMapPoints([]);

  const onSubmit = (values: IRequestForm) => {
    values.defaultFiles = defaultFiles;
    values.invoiceFiles = invoiceFiles;
    values.quoteFiles = quoteFiles;
    values.receiptFiles = receiptFiles;
    values.request_type = reqType;
    values.map_points = mapPoints;
    values.features = features;

    dispatch(submitRequestForm(values));
  };

  return (
    <Form
      keepDirtyOnReinitialize
      initialValues={formInitial}
      onSubmit={onSubmit}
      validate={validate}
      subscription={{ submitting: true }}
    >
      {({ handleSubmit, submitting }) => {
        return (
          <Grid component="form" container gap={6} onSubmit={handleSubmit} id={CREATE_REQUEST_FORM_ID}>
            <Grid item container xs={6} direction="column">
              <Typography variant="body1" fontWeight="semiBold">
                <FormattedMessage id="base" />
              </Typography>

              <Grid item container rowGap={4} direction="column" marginTop={2}>
                <Grid item>
                  <Field
                    name="name"
                    disabled={submitting}
                    label={<FormattedMessage id="label.title" />}
                    component={TextFieldForm}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Field
                    name="description"
                    disabled={submitting}
                    label={<FormattedMessage id="label.description" />}
                    component={TextFieldForm}
                    multiline
                    minRows={3}
                    maxRows={3}
                    fullWidth
                  />
                </Grid>
                <Grid item container>
                  <Grid item xs={9}>
                    <Field
                      fullWidth
                      name="department_id"
                      disabled={submitting}
                      options={departmentsOptions}
                      component={AutocompleteForm}
                      label={<FormattedMessage id="label.department" />}
                    />
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item xs={9}>
                    <Field
                      fullWidth
                      name="cost_centers_ids"
                      disabled={submitting}
                      options={ccOptions}
                      component={AutocompleteForm}
                      multiple
                      label={<FormattedMessage id="label.cost_center" />}
                    />
                  </Grid>
                </Grid>

                {reqType === PAYMENT_REQUEST_TYPE_ENUM.EMERGENCY && (
                  <Grid item container>
                    <Grid item xs={9}>
                      <Field
                        fullWidth
                        name="invoiceType"
                        disabled={submitting}
                        options={invoiceTypeOptions}
                        component={AutocompleteForm}
                        label={<FormattedMessage id="label.invoice_type" />}
                      />
                    </Grid>
                  </Grid>
                )}

                {reqType !== PAYMENT_REQUEST_TYPE_ENUM.CASH && reqType !== PAYMENT_REQUEST_TYPE_ENUM.SALARY && (
                  <Grid item container>
                    <Grid item xs={6}>
                      <Field
                        fullWidth
                        name="expired_at"
                        disabled={submitting}
                        dateTimeFormat={DATE_FORMAT}
                        component={DatePickerForm}
                        format={parseDate}
                        openOnFocus
                        size="medium"
                        label={<FormattedMessage id="label.expiration_date" />}
                      />
                    </Grid>
                  </Grid>
                )}

                {reqType === PAYMENT_REQUEST_TYPE_ENUM.CASH ||
                  (reqType === PAYMENT_REQUEST_TYPE_ENUM.SALARY && (
                    <Grid item container columnGap={1}>
                      <Grid item xs={4}>
                        <Field
                          fullWidth
                          name="amount_currency_id"
                          disabled={submitting}
                          options={currenciesOptions}
                          component={AutocompleteForm}
                          label={<FormattedMessage id="label.currency" />}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Field
                          fullWidth
                          name="exchange_rate"
                          disabled={submitting}
                          label={<FormattedMessage id="label.exchange_rate" />}
                          component={TextFieldMaskedForm}
                        />
                      </Grid>
                    </Grid>
                  ))}

                {reqType !== PAYMENT_REQUEST_TYPE_ENUM.CASH && (
                  <AttachFiles
                    title={<FormattedMessage id="label.attach_file" />}
                    files={defaultFiles}
                    onDrop={(files) => setDefaultFiles((state) => [...state, ...files])}
                    onRemoveFile={removeDefaultFile}
                  />
                )}

                {reqType === PAYMENT_REQUEST_TYPE_ENUM.DEFAULT && (
                  <AttachFiles
                    title={<FormattedMessage id="label.attach_quote" />}
                    files={quoteFiles}
                    onDrop={(files) => setQuoteFiles((state) => [...state, ...files])}
                    onRemoveFile={removeQuoteFile}
                  />
                )}

                {reqType === PAYMENT_REQUEST_TYPE_ENUM.EMERGENCY && (
                  <AttachFiles
                    title={<FormattedMessage id="label.attach_invoice" />}
                    files={invoiceFiles}
                    onDrop={(files) => setInvoiceFiles((state) => [...state, ...files])}
                    onRemoveFile={removeInvoiceFile}
                  />
                )}

                {reqType === PAYMENT_REQUEST_TYPE_ENUM.CASH && (
                  <AttachFiles
                    title={<FormattedMessage id="label.attach_receipt" />}
                    files={receiptFiles}
                    onDrop={(files) => setReceiptFiles((state) => [...state, ...files])}
                    onRemoveFile={removeReceiptFile}
                  />
                )}
              </Grid>
            </Grid>

            <Grid item container xs direction="column" rowGap={2} overflow="hidden" minHeight={600}>
              <PinMap
                createRemoveFeature={createRemoveFeature}
                clearAllFeatures={clearAllFeatures}
                loading={loading}
                haveSelectedPoints={!!mapPoints.length}
                features={features}
                setFeatures={setFeatures}
              />
            </Grid>
          </Grid>
        );
      }}
    </Form>
  );
};

export default BaseVariant;
