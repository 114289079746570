import { DATE_FORMAT } from '@/app/consts/app';
import { AutocompleteForm, CheckboxForm, DatePickerForm, TextFieldForm } from '@/components/Form';
import { useAppSelector } from '@/hooks/useAppSelector';
import {
  IContractFormData,
  submitCreateContractForm,
  submitEditContractForm,
} from '@/pages/backoffice/Modals/CreateEditContractModal/actions';
import { paymentPeriodicityOptions } from '@/pages/backoffice/Modals/CreateEditContractModal/consts';
import validate from '@/pages/backoffice/Modals/CreateEditContractModal/validate';
import Button from '@core-ui/button';
import { parseDate } from '@core-ui/dates';
import Modal from '@core-ui/modal';
import { FormControlLabel } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'components/Intl';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { AttachFiles } from './components';
import selector from './selector';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const CreateEditContractModal = (props: IProps) => {
  const { opened, onClose } = props;

  const {
    isModeEdit,
    initialValues,
    redirect,
    refetchSinglePage,
    loading,
    currencyOptions,
    vendorOptions,
    defaultNotificationActive,
  } = useAppSelector(selector);

  const [notificationActive, setNotificationActive] = useState(defaultNotificationActive);
  const [contractFiles, setContractFiles] = useState<File[]>([]);

  useEffect(() => {
    if (defaultNotificationActive) {
      setNotificationActive(defaultNotificationActive);
    }
  }, [defaultNotificationActive]);

  const dispatch = useDispatch();

  const handleNotificationChange = () => {
    setNotificationActive((prevState) => !prevState);
  };

  const handleSubmitForm = (values: IContractFormData) => {
    values.files = contractFiles;
    values.notification_active = notificationActive;

    if (isModeEdit) {
      dispatch(
        submitEditContractForm({
          contractData: values,
          shouldRedirect: redirect,
          refetchSinglePage,
        })
      );
    } else {
      dispatch(
        submitCreateContractForm({
          contractData: values,
          shouldRedirect: redirect,
        })
      );
    }
  };

  const handleOnDropFiles = (files: File[]) => {
    setContractFiles((prevState) => [...prevState, ...files]);
  };

  const handleRemoveFiles = (fileToDelete: File) => {
    setContractFiles((prevState) => prevState.filter((f) => f.name !== fileToDelete.name));
  };

  const title = isModeEdit ? 'label.edit_contract' : 'label.create_new_contract';

  return (
    <Modal
      open={opened}
      maxWidth="xs"
      onClose={onClose}
      title={<FormattedMessage id={title} />}
    >
      <DialogContent>
        <Form
          keepDirtyOnReinitialize
          subscription={{}}
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleSubmitForm}
        >
          {({ handleSubmit }) => (
            <Grid
              container
              component="form"
              direction="column"
              marginTop={3}
              minWidth={480}
              gap={8}
              onSubmit={handleSubmit}
            >
              <Grid item container direction="column" gap={4}>
                <Grid item>
                  <Typography fontWeight="semiBold">
                    <FormattedMessage id="label.base_info" />
                  </Typography>
                </Grid>

                <Grid item>
                  <Field
                    name="name"
                    autoFocus
                    disabled={loading}
                    component={TextFieldForm}
                    label={<FormattedMessage id="label.contract_name" />}
                    fullWidth
                  />
                </Grid>

                <Grid item>
                  <Field
                    name="vendor_id"
                    disabled={loading}
                    options={vendorOptions}
                    component={AutocompleteForm}
                    label={<FormattedMessage id="label.vendor" />}
                    fullWidth
                  />
                </Grid>

                <Grid item>
                  <Field
                    name="expires_at"
                    disabled={loading}
                    dateTimeFormat={DATE_FORMAT}
                    component={DatePickerForm}
                    format={parseDate}
                    size="medium"
                    label={<FormattedMessage id="label.expiry_date" />}
                    fullWidth
                    openOnFocus
                  />
                </Grid>
              </Grid>

              <Grid item container direction="column" gap={4}>
                <Grid item>
                  <Typography fontWeight="semiBold">
                    <FormattedMessage id="label.payment_info" />
                  </Typography>
                </Grid>

                <Grid item>
                  <Field
                    name="currency_id"
                    disabled={loading}
                    options={currencyOptions}
                    component={AutocompleteForm}
                    label={<FormattedMessage id="label.currency" />}
                    fullWidth
                  />
                </Grid>

                <Grid item container gap={4}>
                  <Grid item width={262}>
                    <Field
                      name="periodicity"
                      disabled={loading}
                      options={paymentPeriodicityOptions}
                      component={AutocompleteForm}
                      label={<FormattedMessage id="label.payment" />}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      name="payment_date"
                      size="medium"
                      disabled={loading}
                      dateTimeFormat={DATE_FORMAT}
                      component={DatePickerForm}
                      format={parseDate}
                      openOnFocus
                      label={<FormattedMessage id="label.payment_date" />}
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <Field
                    name="amount"
                    disabled={loading}
                    component={TextFieldForm}
                    type="number"
                    label={<FormattedMessage id="label.price" />}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid item container direction="column" gap={4}>
                <Grid item container justifyContent="space-between">
                  <Typography fontWeight="semiBold">
                    <FormattedMessage id="label.additional_info" />
                  </Typography>
                  <Typography color="textSecondary">
                    <FormattedMessage id="label.optional" />
                  </Typography>
                </Grid>

                <Grid item>
                  <Field
                    name="description"
                    disabled={loading}
                    component={TextFieldForm}
                    multiline
                    minRows={2}
                    label={<FormattedMessage id="label.description" />}
                    fullWidth
                  />
                </Grid>

                <Grid item>
                  <AttachFiles files={contractFiles} onDrop={handleOnDropFiles} onRemoveFile={handleRemoveFiles} />
                </Grid>
              </Grid>

              <Grid item container direction="column" gap={4}>
                <Grid item container justifyContent="space-between">
                  <Typography fontWeight="semiBold">
                    <FormattedMessage id="label.manager_info" />
                  </Typography>
                  <Typography color="textSecondary">
                    <FormattedMessage id="label.optional" />
                  </Typography>
                </Grid>

                <Grid item>
                  <Field
                    name="manager_info.name"
                    disabled={loading}
                    component={TextFieldForm}
                    label={<FormattedMessage id="label.name" />}
                    fullWidth
                  />
                </Grid>

                <Grid item>
                  <Field
                    name="manager_info.phone"
                    disabled={loading}
                    component={TextFieldForm}
                    type="number"
                    label={<FormattedMessage id="label.phone_number" />}
                    fullWidth
                  />
                </Grid>

                <Grid item>
                  <Field
                    name="manager_info.email"
                    disabled={loading}
                    component={TextFieldForm}
                    label={<FormattedMessage id="label.email_address" />}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Divider />

              <Grid item container direction="column" gap={4}>
                <Grid
                  item
                  component={FormControlLabel}
                  control={
                    <Field
                      name="notification_active"
                      component={CheckboxForm}
                      type="checkbox"
                      checked={notificationActive}
                      onChange={handleNotificationChange}
                      size="large"
                    />
                  }
                  label={<FormattedMessage id="label.notify_me" />}
                />

                <Grid item>
                  <Field
                    name="notification.notify_base_date"
                    disabled={!notificationActive || loading}
                    dateTimeFormat={DATE_FORMAT}
                    component={DatePickerForm}
                    format={parseDate}
                    openOnFocus
                    size="medium"
                    label={<FormattedMessage id="label.notification_base_date" />}
                    fullWidth
                  />
                </Grid>

                <Grid item>
                  <Field
                    name="notification.notify_every_days"
                    disabled={!notificationActive || loading}
                    component={TextFieldForm}
                    type="number"
                    label={<FormattedMessage id="label.every_days" />}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  color="blue"
                  size="large"
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  fullWidth
                  label={<FormattedMessage id={isModeEdit ? 'label.edit_contract' : 'label.create_contract'} />}
                />
              </Grid>
            </Grid>
          )}
        </Form>
      </DialogContent>
    </Modal>
  );
};

export default CreateEditContractModal;
