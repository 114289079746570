import { getCostArticleMappingEnabled } from '@/app/selectors';
import State from '@/app/types/state';
import { NoDataContainerSelector } from '@/components/NoDataContainer/NoDataContainer';
import { getAmountFormatter } from '@/dictionary/selector';
import { ApproveDocumentModalData } from '@/pages/backoffice/Modals/command';
import isNil from 'lodash-es/isNil';
import { createSelector } from 'reselect';

const getModalsData = (state: State) => state.backoffice.modals.data as ApproveDocumentModalData;

const getLoading = (state: State) => state.backoffice.modals.loading;

const getTableData = (state: State) => state.backoffice.ledger.approveDocumentModal;

export const getNoDataTableContainerData: NoDataContainerSelector = createSelector([getTableData], (data) => ({
  loading: data.loading,
  loaded: data.loaded,
  hasData: Boolean(data.hasData && data.value && data.value.length > 0),
  error: data.error,
}));

export const getNoDataBadgesContainerData: NoDataContainerSelector = createSelector([getTableData], (data) => ({
  loading: data.loading,
  loaded: data.loaded,
  hasData: !isNil(data.documentTotalPrice),
  error: data.error,
}));

export const getNoDataButtonsContainerData: NoDataContainerSelector = createSelector([getLoading], (loading) => ({
  loading,
  loaded: !loading,
  hasData: true,
  error: undefined,
}));

export default createSelector(
  [getModalsData, getLoading, getTableData, getCostArticleMappingEnabled, getAmountFormatter],
  (data, loading, documentData, costArticleMappingEnabled, amountFormatter) => ({
    id: data?.id,
    parentPage: data?.parentPage,
    requestId: data?.requestId,
    loading,
    documentTotal: documentData.documentTotalPrice,
    totalRemaining: documentData.totalRemaining?.remaining,
    items: documentData.value,
    costArticleMappingEnabled,
    amountFormatter,
  })
);
