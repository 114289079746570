import { Nullable } from '@core-ui/types';
import { ChatDots, PaperPlaneTilt } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import { IMenuOption } from '@/components/Menu/Menu';
import MoreButton from '@/components/MoreButton';
import { DocumentSearchResult } from '@/generated';
import { openBackofficeModal } from '@/pages/backoffice/Modals/actions';
import { SendForApprovalModalData, SendToXeroItemModalData } from '@/pages/backoffice/Modals/command';

interface IProps {
  item: Nullable<DocumentSearchResult>;
}

const LedgerDocumentHeaderMoreButton = (props: IProps) => {
  const { item } = props;
  const dispatch = useDispatch();

  const handleOpenSendToErpModal = () => {
    // в леджере при отправке документов в xero нельзя передавать wallet_id и request_id
    if (item) {
      const invoiceCode = item.erp_category ?? undefined;

      dispatch(openBackofficeModal(new SendToXeroItemModalData(invoiceCode, item.id)));
    }
  };

  const handleOpenSendToApprovalModal = () => {
    if (item) {
      dispatch(openBackofficeModal(new SendForApprovalModalData(item.id, item.erp_category)));
    }
  };

  const options: IMenuOption[] = [
    {
      title: <FormattedMessage id="label.send_to_erp" />,
      dataTestId: 'ledger-single-document-send-to-erp-option',
      icon: <PaperPlaneTilt />,
      onClick: handleOpenSendToErpModal,
      visible: !item?.is_sent_xero,
    },
    {
      title: <FormattedMessage id="label.ask_for_approval" />,
      dataTestId: 'ledger-single-document-send-for-approval-option',
      icon: <ChatDots />,
      onClick: handleOpenSendToApprovalModal,
      visible: true,
    },
  ];

  return <MoreButton options={options} dataTestId="ledger-single-document-more-button" />;
};

export default LedgerDocumentHeaderMoreButton;
