import LoadFileDropzone from '@/components/LoadFileDropzone';
import UploadedFileRow from '@/components/UploadedFileRow/UploadedFileRow';
import { AttachFileIconOcean } from '@core-ui/icons';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

interface IProps {
  title: ReactNode;
  files: Nullable<File[]>;
  onDrop: (files: File[]) => void;
  onRemoveFile: (file: File) => void;
}

const AttachFiles = (props: IProps) => {
  const { title, files, onDrop, onRemoveFile } = props;

  return (
    <Grid container direction="column">
      <Typography variant="body1" fontWeight="semiBold">
        {title}
      </Typography>

      <Grid container item gap={2} marginTop={1}>
        <LoadFileDropzone
          reducerName="test_reducer"
          handleOnDrop={onDrop}
          render={(props, { getRootProps, getInputProps }) => (
            <div {...getRootProps({})} style={{ border: 'none', outline: 'none' }}>
              <input {...getInputProps()} />
              <div style={{ cursor: 'pointer' }}>
                <AttachFileIconOcean />
              </div>
            </div>
          )}
        />

        {files?.map((file, idx) => (
          <UploadedFileRow key={idx} file={file} removeFile={onRemoveFile} />
        ))}
      </Grid>
    </Grid>
  );
};

export default AttachFiles;
