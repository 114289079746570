import Button from '@core-ui/button';
import Modal from '@core-ui/modal';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { AutocompleteForm, TextFieldForm } from '@/components/Form';
import { CashPaymentTypeEnum, WalletInputSchema, WalletUpdateSchema } from '@/generated';
import { useAppSelector } from '@/hooks/useAppSelector';
import { submitCreateWalletForm, submitEditWalletForm } from '@/pages/backoffice/Modals/CreateEditWalletModal/actions';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import { typeOptions } from './consts';
import selector from './selector';
import validate from './validate';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const CreateEditWalletModal = (props: IProps) => {
  const { opened, onClose } = props;

  const { isModeEdit, initialValues, redirect, currencyOptions, loading } = useAppSelector(selector);
  const dispatch = useDispatch();

  const handleSubmitForm = (values: WalletInputSchema | WalletUpdateSchema) => {
    const newValues = {
      ...values,
      currency_id: values?.wallet_type === CashPaymentTypeEnum.WIRE_TRANSFER ? undefined : values?.currency_id,
    };

    if (isModeEdit) {
      dispatch(
        submitEditWalletForm({
          walletData: newValues as WalletUpdateSchema,
          shouldRedirect: redirect,
        })
      );
    } else {
      dispatch(
        submitCreateWalletForm({
          walletData: newValues as WalletInputSchema,
          shouldRedirect: redirect,
        })
      );
    }
  };

  const title = isModeEdit ? 'label.edit_wallet' : 'label.create_new_wallet';

  return (
    <Modal
      open={opened}
      maxWidth="xxs"
      onClose={onClose}
      title={<FormattedMessage id={title} />}
    >
      <DialogContent>
        <Form
          keepDirtyOnReinitialize
          subscription={{ values: true }}
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleSubmitForm}
        >
          {({ handleSubmit, values }) => (
            <Grid container component="form" direction="column" minWidth={326} gap={6} onSubmit={handleSubmit}>
              <Grid item>
                <Field
                  name="name"
                  autoFocus
                  disabled={loading}
                  component={TextFieldForm}
                  label={<FormattedMessage id="label.wallet_name" />}
                  fullWidth
                />
              </Grid>

              {values?.wallet_type !== CashPaymentTypeEnum.WIRE_TRANSFER && (
                <Grid item>
                  <Field
                    name="currency_id"
                    disabled={loading}
                    options={currencyOptions}
                    component={AutocompleteForm}
                    label={<FormattedMessage id="label.currency" />}
                    fullWidth
                  />
                </Grid>
              )}

              <Grid item>
                <Field
                  name="wallet_type"
                  disabled={loading}
                  options={typeOptions}
                  component={AutocompleteForm}
                  label={<FormattedMessage id="label.type" />}
                  fullWidth
                />
              </Grid>

              <DialogActions>
                <Button
                  variant="contained"
                  color="blue"
                  size="large"
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  label={<FormattedMessage id="label.save_wallet" />}
                />
              </DialogActions>
            </Grid>
          )}
        </Form>
      </DialogContent>
    </Modal>
  );
};

export default CreateEditWalletModal;
