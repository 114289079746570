import LoadFileDropzone from '@/components/LoadFileDropzone';
import UploadedFileRow from '@/components/UploadedFileRow';
import { useAppSelector } from '@/hooks/useAppSelector';
import { uploadDocument } from '@/pages/backoffice/Requests/SingleRequest/InvoicesList/actions';
import { FILE_UPLOAD_TYPE } from '@/pages/backoffice/Requests/types';
import Button from '@core-ui/button';
import Modal from '@core-ui/modal';
import { COLOURS } from '@core-ui/styles';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { CloudArrowUp, UploadSimple } from '@phosphor-icons/react';
import { FormattedMessage } from 'components/Intl';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import selector from './selector';
import useStyles from './styles';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const UploadInvoices = (props: IProps) => {
  const { opened, onClose } = props;

  const { loading } = useAppSelector(selector);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [files, setFiles] = useState<File[]>([]);

  const removeFile = (fileToDelete: File) => setFiles((state) => state.filter((f) => f.name !== fileToDelete.name));

  const onUpload = () => {
    dispatch(uploadDocument({ type: FILE_UPLOAD_TYPE.QUOTE, files }));
    setFiles([]);
  };

  return (
    <Modal
      open={opened}
      maxWidth="md"
      onClose={onClose}
      title={
        <>
          <Typography variant="h3">Upload quote</Typography>
          <Typography variant="subtitle2" marginTop={1}>
            One quote per upload (.pdf, .docx, .xlsx, .jpg)
          </Typography>
        </>
      }
    >
      <DialogContent>
        <Grid item container marginTop={4} direction="column">
          <Grid item xs={!files.length ? 12 : 6}>
            <LoadFileDropzone
              reducerName="test_reducer"
              handleOnDrop={(f) => setFiles((state) => [...state, ...f])}
              render={(props, { getRootProps, getInputProps }) => (
                <div
                  {...getRootProps({
                    className: classes.dropzone,
                  })}
                >
                  <input {...getInputProps()} />

                  <div className={classes.dropzoneArea}>
                    <SvgIcon fontSize="large">
                      <CloudArrowUp />
                    </SvgIcon>

                    <Typography variant="body1" marginTop={1}>
                      Drag & drop to upload or
                    </Typography>

                    <Typography variant="body1" color={COLOURS.Fig.Solid[600]}>
                      Click to browse
                    </Typography>
                  </div>
                </div>
              )}
            />
          </Grid>

          <Grid item container xs={6} gap={3} marginTop={4}>
            {files.map((file, idx) => (
              <Grid item key={idx}>
                <UploadedFileRow file={file} removeFile={removeFile} />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <DialogActions>
          <Button
            color="blue"
            size="large"
            variant="contained"
            onClick={onUpload}
            loading={loading}
            disabled={loading}
            label={<FormattedMessage id="label.upload" />}
            startIcon={<UploadSimple />}
          />
        </DialogActions>
      </DialogContent>
    </Modal>
  );
};

export default UploadInvoices;
