import Button from '@core-ui/button';
import Modal from '@core-ui/modal';
import ToggleButtonGroup from '@core-ui/toggle';
import { IToggleButton, ToggleButtonGroupProps } from '@core-ui/toggle/types';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '@/hooks/useAppSelector';
import { invoiceTypeOptions } from '@/pages/backoffice/Modals/CreateEditRequestModal/components/BaseVariant/selector';
import { quoteAsInvoice } from '@/pages/backoffice/Requests/SingleRequest/QuotesList/actions';
import { Check } from '@phosphor-icons/react';
import React, { useState } from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import selector from './selector';

const buttons: IToggleButton[] = invoiceTypeOptions.map((option) => ({
  content: option.label,
  value: option.value,
}));

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const UseQuoteAsInvoiceModal = (props: IProps) => {
  const { opened, onClose } = props;

  const dispatch = useDispatch();
  const { requestId, documentId } = useAppSelector(selector);

  const [invoiceType, setInvoiceType] = useState(buttons[0].value);

  const handleConfirmClick = () => {
    onClose();
    dispatch(
      quoteAsInvoice({
        requestId,
        documentId,
        invoiceType,
      })
    );
  };

  const handleToggleChange: ToggleButtonGroupProps['onChange'] = (_e, value) => {
    if (value) {
      setInvoiceType(value);
    }
  };

  return (
    <Modal
      open={opened}
      maxWidth="xs"
      onClose={onClose}
      title={
        <>
          <Typography variant="h3">Use Quote as Invoice</Typography>

          <Typography variant="subtitle2" color="text.secondary" marginTop={1}>
            Select invoice type
          </Typography>
        </>
      }
    >
      <DialogContent>
        <Grid container justifyContent="space-between">
          <ToggleButtonGroup
            color="grey"
            size="large"
            value={invoiceType}
            buttons={buttons}
            onChange={handleToggleChange}
          />

          <DialogActions>
            <Button
              color="blue"
              size="large"
              variant="contained"
              autoFocus
              onClick={handleConfirmClick}
              label={<FormattedMessage id="label.confirm" />}
              startIcon={<Check />}
            />
          </DialogActions>
        </Grid>
      </DialogContent>
    </Modal>
  );
};

export default UseQuoteAsInvoiceModal;
