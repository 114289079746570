import Button from '@core-ui/button';
import Modal from '@core-ui/modal';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { useAppSelector } from '@/hooks/useAppSelector';
import QuoteItemWrapper from '@/pages/backoffice/Modals/AddQuotesToCompareModal/components/QuoteItem';
import { compareQuotes } from '@/pages/backoffice/Requests/SingleRequest/QuotesCompare/actions';
import QuoteListHeader from '@/pages/backoffice/Requests/SingleRequest/QuotesList/components/QuoteListHeader';
import React from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import selector from './selector';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const AddQuotesToCompareModal = (props: IProps) => {
  const { opened, onClose } = props;

  const { quotes } = useAppSelector(selector);
  const dispatch = useDispatch();

  const handleConfirm = () => {
    dispatch(compareQuotes({ quotes }));
    onClose();
  };

  return (
    <Modal
      open={opened}
      maxWidth="xl"
      onClose={onClose}
      title={<FormattedMessage id="modal.backoffice.add_quotes.title" />}
    >
      <DialogContent>
        <QuoteListHeader />

        <Grid container marginTop="2px" gap="2px">
          {quotes?.map((quote) => (
            <QuoteItemWrapper key={quote.id} quote={quote} />
          ))}
        </Grid>

        <DialogActions>
          <Button
            variant="contained"
            color="blue"
            size="large"
            autoFocus
            onClick={handleConfirm}
            label={<FormattedMessage id="quotes.go_compare" />}
          />
        </DialogActions>
      </DialogContent>
    </Modal>
  );
};

export default AddQuotesToCompareModal;
